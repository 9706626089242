import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import { services } from "../../data/data";
import { AiOutlineLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";

const Services = () => {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    setSelected(i);
  };
  
  return (
    <div className="lg:mt-14 flex flex-col w-full">
      <div className="mt-8 text-left px-6 flex flex-col">
        <header className="text-3xl font-semibold">Services we offer</header>
        <div className="h-[0.145rem] w-16 my-2 bg-purple-400"></div>
      </div>
      <div className="flex h-full w-full justify-center items-center px-6">
        <div>
          {services.map((values, index) => (
            <div key={index} className="my-5 shadow-lg">
              <header
                onClick={() => toggle(index)}
                className="text-lg text-white flex flex-row items-center justify-between font-roboto font-semibold my-3 h-16 align-middle bg-gradient-to-b from-[#7733FF] to-[#7733FF]  px-3 cursor-pointer"
              >
                <p>{values.title}</p>
                <span>
                  {selected === index ? (
                    <KeyboardControlKeyIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </span>
              </header>
              <article
                className={`text-base leading-8 font-roboto px-3 transition-all ${
                  selected === index
                    ? "max-h-auto overflow-visible"
                    : "max-h-0 overflow-hidden"
                }`}
              >
                
                {values.description}
              </article>
            </div>
          ))}
          <div className="my-6 shadow-lg">
  <header className="flex flex-col font-bold text-2xl text-left md:text-left my-3">
    Get in Touch
    <div className="h-[0.145rem] bg-purple-400 w-1/3 lg:w-[7%]"></div>
  </header>
  <section className="flex h-auto w-auto flex-row font-semibold text-left text-sm lg:text-lg md:text-left leading-8 lg:leading-loose text-[#404040]">
    <p>
      Are you looking for a trusted partner to help you to set up your business in India? Are you an Indian company wanting to grow globally? We are your trusted partners.
    </p>
  </section>
  <br></br>
  <p className="font-bold text-xl">Please contact us:</p>
     
     <header className="flex flex-col font-bold text-xl text-lg text-left md:text-left my-3 ">Email:</header>
     <ol className="font-semibold hover:text-purple-400">
       <li>tarakglobal@gmail.com</li> 
       <li>thayyilramesh9@gmail.com</li> 
       </ol>
  <header className="flex text-xl flex-col font-bold text-lg text-left md:text-left my-3 ">Phone:
  </header>
  <ol>
      <li>+918605373028</li>
      <li>+919623074249</li>
      
      </ol>
      <header className="flex flex-col font-bold text-xl text-left md:text-left my-3 ">Address</header>
      <ol>
          <li>A/212, Vora Plaza, Near Ambaji Mandir, Navghar,</li>
          <li>Vasai Road East, Via. Mumbai,</li>
          <li>Maharashtra, India - 401210. </li>       
      </ol>
      <header className="flex flex-col font-bold text-xl text-left md:text-left my-3">Social</header>
<div className="flex items-center hover:text-purple-400">
  <a href="https://www.linkedin.com/in/ramesh-achuthan-thayyil-12a93422/" target="_blank" rel="noopener noreferrer">
    <AiOutlineLinkedin size={30} className="mr-2" />
  </a>
</div>
      
      








</div>
        </div>
      </div>
    </div>
  );
};

export default Services;
