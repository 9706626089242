import React from "react";
import banner from "../../Assets/banner4.jpg";
import { contactInformation } from "../../data/data";
import Card from "../../Components/Card/Card";

const Contact = () => {
  return (
    <div className="lg:mt-20 h-full">
      <span className="flex flex-col items-center md:flex-row my-3 mx-4">
        <div className="flex flex-col leading-10 font-roboto">
          <header className="flex flex-col font-bold text-2xl my-2">
            <p>Reach out to us</p>
            <div className="w-10 h-[0.2rem] bg-[#6F49FC]"></div>
          </header>
          <article className="font text-lg my-2">
            Are you looking for a trusted partner to help you to set up your
            business in India ? Are you an Indian company wanting to grow
            globally? We are your trusted partners.
          </article>
        </div>
        <img
          src={banner}
          alt="banner"
          className="h-60 w-60 sm:h-100 sm:w-100 md:rounded-full"
        />
      </span>
      <span className="grid grid-cols-1 mx-5 sm:grid-cols-2 lg:grid-cols-4">
        {contactInformation.map((values, index) => (
          <div key={values.id}>
            <Card
              icon={values.icon}
              title={values.title}
              description={values.description}
            />
          </div>
        ))}
      </span>
    </div>
    
  );
};

export default Contact;
