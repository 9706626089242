import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselImg } from "../../data/data";

const Carousel = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };
  return (
    <Slider {...settings}>
      {carouselImg.map((values, index) => (
        <div key={index}>
          <img
            src={values.source}
            alt="Banner"
            key={index}
            className="mr-16 w-[90%] ml-5 h-80 lg:h-[25rem] lg:w-[60%] lg:mx-72"
          />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
