import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import About from "./Screens/About/About";
import Services from "./Screens/Services/Services";
import Contact from "./Screens/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Vision from "./Screens/Vision/Vision"
import Activity from "./Screens/Activity/Activity"
function App() {
  return (
    <div className="z-0">
      <Navbar />
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/vision" element={<Vision/>} /> 
        <Route path="/activity" element={<Activity/>} />       
      </Routes>
     
    </div>
  );
}

export default App;
