import React from "react";

const Card = (props) => {
  return (
    <div className="flex flex-row shadow-md w-[92%] md:w-5/6 lg:w-5/6 my-4 h-24 items-center justify-center px-4 py-2">
      <span>{props.icon}</span>
      <section className="text-base font-thin text-[#404040] w-full">
        {props.description}
      </section>
    </div>
  );
};

export default Card;
