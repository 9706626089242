import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineLinkedin } from "react-icons/ai";


const Vision = () => {
  return (
    <div className="lg:mt-20">
      <div className="mx-5 lg:mx-16 my-3">
        
        <div className="flex flex-col md:flex-row my-12 items-center">
          <img
            src="https://res.cloudinary.com/dxity6o7b/image/upload/v1683140262/JSW_tpmgqe.jpg"
            alt="banner1"
            className="h-52 w-48 sm:h-52 sm:w-80"
          />
          <div className="flex flex-col leading-10 font-roboto my-3 mx-12">
            <header className="flex flex-col font-semibold text-xl">
              <p className={`text-[#FC496F] text-2xl`}>Our Team</p>
              <div className="w-10 h-[0.2rem] bg-[#6F49FC]"></div>
            </header>
            <article className="font-semibold text-[#404040] text-xl mx-1 my-3">
            Our CEO has four decades of leadership experience in
    various industries like ship building, welding machines, welding
    consumables, alloy castings and fabrication. A metallurgical engineer
    with post graduation in management, he has also completed a global
    strategic management program and attended many international business
    summits. He comes with extensive international business exposure
    acquired through work experience in the Middle East, China, USA as well as
    many countries in Africa, Europe and around the world. We have a
    committed team of marketing and technical professionals to assist him.
    We also have a pool of freelancers to choose from based on the
    specific skills needed for projects undertaken.
            </article>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <img
            src="https://res.cloudinary.com/dxity6o7b/image/upload/v1681489276/3_j3puzc.jpg"
            alt="banner3"
            className="h-80 w-60 sm:h-52 sm:w-80"
          />
          <br></br>
          <div className="flex flex-col leading-10 font-roboto my-3 mx-12 md:ml-20">
            <header className="flex flex-col font-semibold text-xl">
              <p className="text-[#37E200] text-2xl">Summary of Activities</p>
              <div className="w-10 h-[0.2rem] bg-[#6F49FC]"></div>
            </header>
            <article className="font-bold text-[#404040] text-xl mx-1 my-3">
            We have handled projects for radiography machines, welding
    consumables, metal fabrication, steel grinding media, dry wall cutting
    machines and high nickel alloys. Currently we are representing Taisei
    International Corporation, Japan and Flextos Gmbh Germany.
            </article>
          </div>
        </div>
        <br></br>
        
        <div className="flex flex-col">
  <header className="flex flex-col font-bold  text-left md:text-left my-3 text-2xl">
    Get in Touch
    <div className="h-[0.2rem] bg-purple-400 w-1/3 lg:w-[7%]"></div>
  </header>
  <div className="text-left"> {/* add text-center class */}
    <section className="font-semibold text-sm lg:text-lg leading-8 text-[#404040]">
      <p>
        Are you looking for a trusted partner to help you to set up your business in India? Are you an Indian company wanting to grow globally? We are your trusted partners.
      </p>
      <br></br>
      <p className="font-bold text-xl">Please contact us:</p>
     
      <header className="flex flex-col font-bold text-lg text-left md:text-left my-3 text-xl ">Email:</header>
      <ol className="font-semibold hover:text-purple-400">
        <li>tarakglobal@gmail.com</li> 
        <li>thayyilramesh9@gmail.com</li> 
        </ol>
      
      <header className="flex flex-col font-bold text-lg text-left md:text-left my-3 text-xl ">
      Phone :          
      </header>
      <ol>
      <li>+918605373028</li>
      <li>+919623074249</li>
      
      </ol>
      <header className="flex flex-col font-bold text-lg text-xl text-left md:text-left my-3 ">Address</header>
      <ol>
          <li>A/212, Vora Plaza, Near Ambaji Mandir, Navghar,</li>
          <li>Vasai Road East, Via. Mumbai,</li>
          <li>Maharashtra, India - 401210. </li>       
      </ol>
      <header className="flex flex-col font-bold text-lg text-left md:text-left my-3 text-xl">Social</header>
      <div className="flex items-center hover:text-purple-400 ">
  <a href="https://www.linkedin.com/in/ramesh-achuthan-thayyil-12a93422/" target="_blank" >
    <AiOutlineLinkedin size={30} className="mr-2" />
  </a>
  
</div>

    </section>
  </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
