import React from "react";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";

const Menu = ({ closeMenu }) => {
  const handleLinkClick = () => {
    closeMenu();
  };

  return (
    <div className="flex flex-col font-roboto font-light w-full shadow-lg bg-white z-20 top-16">
      <Divider />
      <NavLink
        to="/"
        onClick={handleLinkClick}
        className={({ isActive }) =>
          isActive
            ? "bg-black text-white px-2 py-0.5 my-1.5 mx-2 rounded-full"
            : "bg-white text-black px-2 py-0.5 my-1.5 mx-2"
        }
      >
        About us
      </NavLink>
      <NavLink
        to="/vision"
        onClick={handleLinkClick}
        className={({ isActive }) =>
          isActive
            ? "bg-black text-white px-2 py-0.5 my-1.5 mx-2 rounded-full"
            : "bg-white text-black px-2 py-0.5 my-1.5 mx-2"
        }
      >
        Vision & Mission
      </NavLink>
      <Divider />
      <NavLink
        to="/activity"
        onClick={handleLinkClick}
        className={({ isActive }) =>
          isActive
            ? "bg-black text-white px-2 py-0.5 my-1.5 mx-2 rounded-full"
            : "bg-white text-black px-2 py-0.5 my-1.5 mx-2"
        }
      >
        Our Team & Activities
      </NavLink>
      <Divider />
      <NavLink
        to="/services"
        onClick={handleLinkClick}
        className={({ isActive }) =>
          isActive
            ? "bg-black text-white px-2 py-0.5 my-1.5 mx-2 rounded-full"
            : "bg-white text-black px-2 py-0.5 my-1.5 mx-2"
        }
      >
        Services
      </NavLink>
      
      <NavLink
        to="/contact"
        onClick={handleLinkClick}
        className={({ isActive }) =>
          isActive
            ? "bg-black text-white px-2 py-0.5 my-1.5 mx-2 rounded-full"
            : "bg-white text-black px-2 py-0.5 my-1.5 mx-2"
        }
      >
        Contact us
      </NavLink>
      <Divider />
    </div>
  );
};

export default Menu;
