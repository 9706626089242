import { AiOutlineLinkedin } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
const carouselImg = [
  {
    id: 1,
    source:
      "https://res.cloudinary.com/dxity6o7b/image/upload/v1681489276/1_pvsktk.jpg",
  },
  // {
  //   id: 2,
  //   source:
  //     "https://res.cloudinary.com/dxity6o7b/image/upload/v1681489276/2_zwnpkn.jpg",
  // },
  // {
  //   id: 3,
  //   source:
  //     "https://res.cloudinary.com/dxity6o7b/image/upload/v1681489276/3_j3puzc.jpg",
  // },
  // {
  //   id: 4,
  //   source:
  //     "https://res.cloudinary.com/dxity6o7b/image/upload/v1681489276/4_n3zhlc.jpg",
  // },
  // {
  //   id: 5,
  //   source:
  //     "https://res.cloudinary.com/dxity6o7b/image/upload/v1681501045/bg_czrbyz.jpg",
  // },
];

const aboutUs = [
  {
    id: 1,
    title: <p className="mx text-2xl ">About Us</p>,
    description: <p className="mx-1 text-xl my-2">Tarak Global Enterprises was established in 2020 as a consultancy and project management firm. 

    We undertake projects for market research, market intelligence studies, marketing, business growth, feasibility studies and for setting up new factories or divisions. 
    
    </p>,
    image: "../Assets/banner.jpeg",
  },
  
  
];
const contactInformation = [
  {
    id: 1,
    icon: <AiOutlineLinkedin size={40} color="#6F49FC" />,
    title: (
      <a
        target="_blank"
        href="https://www.linkedin.com/company/tarak-global-enterprises/"
        className="font-bold"
      >
        TARAK GLOBAL ENTERPRISES
      </a>
    ),
    description: (
      <a href="https://www.linkedin.com/company/tarak-global-enterprises/" target="_blank">
        <b>TARAK GLOBAL ENTERPRISES</b>
      </a>
    ),
  },
  
  {
    id: 2,
    icon: <AiOutlineLinkedin size={40} color="#6F49FC" />,
    title: (
      <a 
        target="_blank"
        href="https://www.linkedin.com/company/tarak-global-enterprises/"
        className="font-bold"
      >
        Ramesh Achuthan Thayyil
      </a>
    ),
    description: (
      <a href="https://www.linkedin.com/in/ramesh-achuthan-thayyil-12a93422/" target="_blank">
        <b>Ramesh Achuthan Thayyil</b>
      </a>
    ),
  },
  {
    id: 3,
    icon: <FiPhone size={40} color="#6F49FC" />,
    title: <b>+91 8605373028</b>,
    description: <b>+91 8605373028</b>,
  },
  {
    id: 4,
    icon: <FiPhone size={40} color="#6F49FC" />,
    title: <b>+91 9623074249</b>,
    description: <b>+91 9623074249</b>,
  },
  {
    id: 4,
    icon: <AiOutlineMail size={40} color="#6F49FC" />,
    title: <b>tarakglobal@gmail.com</b>,
    description: <b>tarakglobal@gmail.com</b>,
  },
  {
    id: 5,
    icon: <AiOutlineMail size={40} color="#6F49FC" />,
    title: <b>thayyilramesh9@gmail.com</b>,
    description: <b>thayyilramesh9@gmail.com</b>,
  },
];

const services = [
  {
    id: 1,
    title: "Marketing ( to develop new territories or new brands )",
    description:
      "In-depth market understanding of both domestic and international markets.",
  },
  {
    id: 2,
    title: "Market potential analysis",
    description:
      "Choosing the right markets / market segments and analyzing potential in the chosen segments.",
  },
  {
    id: 1,
    title: "Liaisoning with government agencies for new projects.",
    description:
      "We provide expert liaisoning services with government agencies to ensure seamless execution of new projects.",
  },
  {
    id: 1,
    title: "Manpower selection",
    description:
      "Our Manpower Selection service offers a comprehensive approach to hiring the right talent for your organization, with a focus on finding individuals who fit both the technical and cultural requirements of your business.",
  },
  {
    id: 1,
    title: "Customer approvals",
    description:
      "Our Customer Approval service ensures that your customers are fully satisfied with your products or services by gathering feedback and addressing any concerns they may have, ultimately driving customer loyalty and repeat business.",
  },
  {
    id: 1,
    title: "Test marketing",
    description:
      "Our Test Marketing service offers a strategic approach to launching new products or services, allowing you to gauge market demand, identify potential challenges, and refine your offerings before going to market.",
  },
];
export { carouselImg, aboutUs, contactInformation, services };
