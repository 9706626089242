import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineLinkedin } from "react-icons/ai";

const Vision = () => {
  return (
    <div className="lg:mt-20">
      <div className="mx-5 lg:mx-16 my-3">
        
        <div className="flex flex-col md:flex-row my-12 items-center">
          <img
            src="https://res.cloudinary.com/dxity6o7b/image/upload/v1682163425/GuysHilltop_dcbw8h.jpg"
            alt="banner1"
            className="h-52 w-60 sm:h-52 sm:w-80"
          />
          <div className="flex flex-col leading-10 font-roboto my-3 mx-12">
            <header className="flex flex-col font-semibold text-xl">
              <p className={`text-[#FC496F] text-2xl`}>Our Vision</p>
              <div className="w-10 h-[0.2rem] bg-[#6F49FC]"></div>
            </header>
            <article className="font-semibold text-[#404040] text-xl my-2">
              To be a trusted partner in developing our clients' businesses
            </article>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <img
            src="https://res.cloudinary.com/dxity6o7b/image/upload/v1682163425/SucessWoman_setbuc.jpg"
            alt="banner1"
            className="h-52 w-60 sm:h-52 sm:w-80"
          />
          <div className="flex flex-col leading-10 font-roboto my-3 mx-12">
            <header className="flex flex-col font-semibold text-xl">
              <p className="text-[#37E200] text-2xl">Our Mission</p>
              <div className="w-10 h-[0.2rem] bg-[#6F49FC]"></div>
            </header>
            <article className="font-semibold text-[#404040] text-xl my-2">
              To evolve the client’s business right from an idea to its birth
              and growth
            </article>
          </div>
        </div>
        <br></br>
        <div className="flex flex-col">
  <header className="flex flex-col font-bold  text-left md:text-left my-3 text-2xl">
    Get in Touch
    <div className="h-[0.2rem] bg-purple-400 w-1/3 lg:w-[7%]"></div>
  </header>
  <div className="text-left"> {/* add text-center class */}
    <section className="font-semibold text-sm lg:text-lg leading-8 text-[#404040]">
      <p>
        Are you looking for a trusted partner to help you to set up your business in India? Are you an Indian company wanting to grow globally? We are your trusted partners.
      </p>
      <br></br>
      <p className="font-bold text-xl">Please contact us:</p>
     
      <header className="flex flex-col font-bold text-lg text-left md:text-left my-3 text-xl ">Email:</header>
      <ol className="font-semibold hover:text-purple-400">
        <li>tarakglobal@gmail.com</li> 
        <li>thayyilramesh9@gmail.com</li> 
        </ol>
      
      <header className="flex flex-col font-bold text-lg text-left md:text-left my-3 text-xl ">
      Phone :          
      </header>
      <ol>
      <li>+918605373028</li>
      <li>+919623074249</li>
      
      </ol>
      <header className="flex flex-col font-bold text-lg text-xl text-left md:text-left my-3 ">Address</header>
      <ol>
          <li>A/212, Vora Plaza, Near Ambaji Mandir, Navghar,</li>
          <li>Vasai Road East, Via. Mumbai,</li>
          <li>Maharashtra, India - 401210. </li>       
      </ol>
      <header className="flex flex-col font-bold text-lg text-left md:text-left my-3 text-xl">Social</header>
      <div className="flex items-center hover:text-purple-400 ">
  <a href="https://www.linkedin.com/in/ramesh-achuthan-thayyil-12a93422/" target="_blank" >
    <AiOutlineLinkedin size={30} className="mr-2" />
  </a>
  
</div>


    </section>
  </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
