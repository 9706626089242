import React from "react";
import logo from "../../Assets/logo.png";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import { NavLink, Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="hidden absolute inset-x-0 top-0 lg:flex flex-row items-center mx-3">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-16 w-32 -ml-3" />
        </Link>
        <ul className="flex flex-row gap-12 ml-5">
          <li>
            <NavLink
              to="/"
              exact
              activeClassName="bg-black text-white px-4 py-2 rounded-full font-bold text-lg"
              className="bg-white text-black px-4 py-2 font-semibold text-lg"
            >
              About us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/vision"
              activeClassName="bg-black text-white px-4 py-2 rounded-full font-bold text-lg"
              className="bg-white text-black px-4 py-2 font-semibold text-lg"
            >
              Vision & Mission
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/activity"
              activeClassName="bg-black text-white px-4 py-2 rounded-full font-bold text-lg"
              className="bg-white text-black px-4 py-2 font-semibold text-lg"
            >
              Our Team & Activities
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              activeClassName="bg-black text-white px-4 py-2 rounded-full font-bold text-lg"
              className="bg-white text-black px-4 py-2 font-semibold text-lg"
            >
              Services
            </NavLink>
          </li>
          
          <li>
            <NavLink
              to="/contact"
              activeClassName="bg-black text-white px-4 py-2 rounded-full font-bold text-lg"
              className="bg-white text-black px-4 py-2 font-semibold text-lg"
            >
              Contact us
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="lg:hidden xl:hidden 2xl:hidden">
        <MobileNavbar />
      </div>
    </>
  );
};

export default Navbar;
