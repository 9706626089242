import React, { useState } from "react";
import logo from "../../Assets/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import Menu from "./Menu";
import { Link } from "react-router-dom";

const MobileNavbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <div className="flex flex-col mx-3 mt-2.5">
      <div className="flex flex-row justify-between items-center">
        <Link to="/"><img src={logo} alt="Logo" className="h-14 -ml-5" /></Link>
        <button onClick={() => setShowMenu(!showMenu)}>
          <GiHamburgerMenu />
        </button>
      </div>
      {showMenu && <Menu closeMenu={closeMenu} />}
    </div>
  );
};

export default MobileNavbar;
